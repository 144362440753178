import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import './services.css';

// import Typical from 'react-typical';
import RotatingText from "../../Components/RotateText/RotateText";
import { MdKeyboardArrowRight } from "react-icons/md";
import dataDash from './data dashboard.png';
import marketingDash from './marketing dashboard.png';
import fortifyDash from './fortify dash.png';
import vrImage from './al knowledge (5).png';
import listingDash from './listing.png';
import datastateLogo from './logo1.png';
import fortifyLogo from './logo1 (1).png';
import lawtrackLogo from './lawtrack-220x54.png';
import realtorsLogo from './realtorstrack.pk.png';

const Services = () => {

    const { t } = useTranslation();

    const [b2bClicked, setB2bClicked] = useState(true);
    const [b2cClicked, setB2cClicked] = useState(false);

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };


    return (
        <>
            <section id="service_section">
                <h1 id="service_hdng">
                    {t('services.main_heading')}
                </h1>
                <p id="service_txt">
                    {t('services.sub_heading')}
                </p>


                <div id="switch_btn_div">
                    <button id={b2bClicked ? 'clicked' : 'switch_btn'}
                        onClick={() => { setB2bClicked(true); setB2cClicked(false); }}>
                        B2B
                    </button>
                    <button id={b2cClicked ? 'clicked' : 'switch_btn'}
                        onClick={() => { setB2cClicked(true); setB2bClicked(false); }}>
                        B2C
                    </button>
                </div>

                {b2bClicked

                    ?

                    (<div id="services_div"
                        onDragEnd={() => { setB2cClicked(true); setB2bClicked(false); }}>

                        {/* fortify */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('services.fortify.heading')}
                                </h3>
                                <span
                                    id="powered_by_txt">
                                    {t('services.fortify.powered_by')} <img id="powered_by_img" src={fortifyLogo} alt="" style={{ width: '45px' }} />
                                </span>
                                <p className="service_item_txt">
                                    {t('services.fortify.text')}
                                    {/* <RotatingText
                                        texts={[
                                            'Manage customer relationships and business administration with comprehensive CRM tools.',
                                            'Leverage WhatsApp and social media integrations for seamless communication and lead management.',
                                            'Efficiently handle client documents, campaign management, and maintain a structured contact book.'
                                        ]}
                                        interval={3000}
                                    /> */}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#0C9780' }}>
                                    <Link id="link" target='_blank' to='https://fortify.landtrack.pk/'>
                                        {t('services.fortify.btn')} <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={fortifyDash} alt="" />
                            </div>
                        </div>

                        {/* ar/vr */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={vrImage} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('services.vr.heading')}
                                </h3>
                                <p className="service_item_txt">
                                    {t('services.vr.text')}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#F11927' }}>
                                    <Link id="link" target='_blank' to='https://proptech-services-page.netlify.app/'>
                                        {t('services.vr.btn')} <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>

                        {/* datastate */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('services.datastate.heading')}
                                </h3>
                                <span id="powered_by_txt">
                                    {t('services.datastate.powered_by')} <img id="powered_by_img" src={datastateLogo} alt="" /></span>
                                <p className="service_item_txt">
                                    {t('services.datastate.text')}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#3E239D' }}>
                                    <Link id="link" target='_blank' to='https://www.datastate.ai/'>
                                        {t('services.datastate.btn')} <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={dataDash} alt="" />
                            </div>
                        </div>

                        {/* marketing */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={marketingDash} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('services.developer.heading')}
                                </h3>
                                <p className="service_item_txt">
                                    {t('services.developer.text')}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#F11927' }}>
                                    <Link id="link" target='_blank' to='https://proptech-services-page.netlify.app/'>
                                        {t('services.developer.btn')} <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>

                        {/* listing */}
                        <div className="service_item_div" style={{ margin: '0', padding: '0 40px' }}>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('services.listing.heading')}
                                </h3>

                                <p className="service_item_txt">
                                    {t('services.listing.text')}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#F11927' }}>
                                    <Link id="link" target='_blank' to='https://proptech-services-page.netlify.app/'>
                                        {t('services.listing.btn')} <MdKeyboardArrowRight />
                                    </Link>
                                </button>

                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={listingDash} alt="" />
                            </div>
                        </div>

                    </div>)

                    :

                    (<div id="services_div"
                        onDragEnd={() => { setB2bClicked(true); setB2cClicked(false); }}>

                        {/* datastate */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('services.datastate.heading')}
                                </h3>
                                <span id="powered_by_txt">
                                    {t('services.datastate.powered_by')} <img id="powered_by_img" src={datastateLogo} alt="" /></span>
                                <p className="service_item_txt">
                                    {t('services.datastate.text')}
                                    {/* <RotatingText
                                        texts={[
                                            'Detailed property market trends and pricing analysis to guide your investments.',
                                            'Predictive analytics to forecast property value changes and market conditions.',
                                            'Customised reports on neighbourhood demographics, crime rates, and school ratings.'
                                        ]}
                                        interval={3000}
                                    /> */}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#3E239D' }}>
                                    <Link id="link" target='_blank' to='https://www.datastate.ai/'>
                                        {t('services.datastate.btn')} <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={dataDash} alt="" />
                            </div>
                        </div>

                    </div>)
                }


            </section>
        </>
    );
};

export default Services;