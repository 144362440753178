import React from 'react';

const DatastatePage = () => {

    return (
        <>
            <iframe src="https://datastate.ai/" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
        </>
    );
};

export default DatastatePage;