import React from "react";
import { useTranslation } from "react-i18next";

import './blogs.css';

import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import economyImg from './MAX_9296-1024x683-1-1024x600.jpg';
import partnerImg from './technology in real estate (13).png';
import samanaImg from './Samana_1900676d1a3_medium.webp';
import { MdKeyboardArrowRight } from "react-icons/md";


const Blogs = () => {

    const { t } = useTranslation();

    return (
        <>
            <section id="blogs_section">
                <h5 id="blog_sbhdng">
                    {t('blogs.sub_heading')}
                </h5>
                <h1 id="blog_hdng">
                    {t('blogs.main_heading')}
                </h1>

                <div id="blog_cards_container">

                    <MDBCard className="blogs_card">
                        <MDBRipple id="blog_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage style={{ height: '100 %', width: '100%' }} src={samanaImg} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">
                                {t('blogs.blog_1.heading')}
                            </MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                {t('blogs.blog_1.text')}
                            </MDBCardText>
                            <MDBBtn
                                onClick={() => window.location.href = 'https://blog.menaproptech.com/2024/07/16/digital-transformation-in-mena-real-estate/'}
                                id="blog_card_btn">
                                {t('blogs.blog_1.btn')}
                                <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} /></MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="blogs_card">
                        <MDBRipple id="blog_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage style={{ height: '100 %', width: '100%' }} src={partnerImg} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">
                                {t('blogs.blog_2.heading')}
                            </MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                {t('blogs.blog_2.text')}
                            </MDBCardText>
                            <MDBBtn
                                onClick={() => window.location.href = 'https://blog.menaproptech.com/2024/07/16/international-real-estate-partnerships/'}
                                id="blog_card_btn">
                                {t('blogs.blog_2.btn')}
                                <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} /></MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="blogs_card">
                        <MDBRipple id="blog_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage style={{ height: '100%', width: '100%' }} src={economyImg} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">
                                {t('blogs.blog_3.heading')}
                            </MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                {t('blogs.blog_3.text')}
                            </MDBCardText>
                            <MDBBtn
                                onClick={() => window.location.href = 'https://blog.menaproptech.com/2024/06/26/pak-uae-economic-relationship/'}
                                id="blog_card_btn">
                                {t('blogs.blog_3.btn')}
                                <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} /></MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                </div>

                {/* <button
                    onClick={() => window.location.href = 'https://blog.landtrack.pk/'}
                    id="blog_btn">Read All Blogs <MdKeyboardArrowRight />
                </button> */}

            </section>
        </>
    );
};

export default Blogs;