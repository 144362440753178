import React from "react";

import Navigation from "../../Components/Navigation/Navigation";
import Home from "../../sections/Home/Home";
import About from "../../sections/About/About";
import Services from "../../sections/OurServices/Services";
import Blogs from "../../sections/Blogs/Blogs";
import Form from "../../Components/Form/Form";
import Footer from "../../Components/Footer/Footer";


const HomePage = () => {

    return (
        <>
            <Navigation />
            <Home />
            <About />
            <Services />
            <Blogs />
            <Form />
            <Footer />
        </>
    );
};

export default HomePage;