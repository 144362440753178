import React from 'react';

const MarketPlacePage = () => {

    return (
        <>
            <iframe src="http://marketplace.landtrack.pk/" style={{ width: '100%', height: '', border: 'none' }}></iframe>
        </>
    );
};

export default MarketPlacePage;