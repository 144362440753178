import React from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";

import logo from './mena-logo-white-2-768x768.png';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";


const Footer = () => {

    const { t } = useTranslation();

    const handleCall = () => {
        window.location.href = `tel:+971 50 260 0721`
    };

    const handleMail = () => {
        window.location.href = `https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSHwCrzgNQbZrPVqJbCHfmZhmjBsVvgmRZxrFHvMrbDrHmrZdvVrZQpQmDjkBDPFFcwVjRZC`
    };

    return (
        <>
            <section id="footer_section">

                <div id="footer_Info_div">
                    <img id="footer_logo" src={logo} alt="" />

                    <p id="footer_info_txt">
                        {t('footer.text')}
                    </p>
                </div>

                <div id="footer_contact_div">
                    <h5 id="footer_links_hdng">
                        {t('footer.contact_heading')}
                    </h5>
                    <div id="footer_links_div">
                        <div style={{ width: '50%', flexWrap: 'wrap' }}>
                            <p
                                onClick={handleCall}
                                className="footer_links_txt" id="phone"><FaPhoneAlt /> +971 50 260 0721</p>
                            <p
                                onClick={handleMail}
                                className="footer_links_txt" id="mail"><IoMdMail /> info@menaproptech.com</p>
                        </div>

                        {/* <p className="footer_links_txt" id="address" style={{ width: "70%" }}><FaLocationDot /> SA-48, 3rd Floor, Shahnaz Arcade, Shaheed-E-Millat Rd, Karachi</p> */}
                    </div>

                    <h5 id="social_hdng">
                        {t('footer.social_heading')}
                    </h5>
                    <div id="social_icns_div">
                        <FaFacebook
                            onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=61552577107247'}
                            style={{ color: 'whitesmoke', fontSize: '20px', cursor: 'pointer' }}
                        />
                        <AiFillTikTok
                            onClick={() => window.location.href = 'https://www.tiktok.com/@menaproptech?lang=en'}
                            style={{ color: 'whitesmoke', fontSize: '22px', cursor: 'pointer' }}
                        />
                    </div>
                </div>

            </section >
        </>
    );
};

export default Footer;