import React, { useState, useEffect } from 'react';
// import { Debounce } from 'react-lodash';
import { useTranslation } from 'react-i18next';

import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

import logo from "./Untitled-design-1-removebg-preview-1 (1).png";

import "./navigation.css";

import LanguageSwitch from '../LangSwitch/LangSwitch';

// functionality...

const Navigation = () => {

    const { t } = useTranslation();

    const [showNavSecond, setShowNavSecond] = useState(false);
    const [openBasic, setOpenBasic] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isToggeled, setIsToggeled] = useState(false);
    // const [isMobile, setIsMobile] = useState(checkResponsive());

    const checkResponsive = () => {
        return window.innerWidth < 450;
    };

    const scrollHandler = () => {
        if (window.pageYOffset > 100) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const scrollCheck = () => {
        if (window.pageYOffset > 10) {
            setOpenBasic(false);
        }
    };

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth",
            });
        }
    };

    const handleCall = () => {
        window.location.href = `tel:+971 50 260 0721`
    };

    const handleMail = () => {
        window.location.href = `mailto:info@menaproptech.com`
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('scroll', scrollCheck);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('scroll', scrollCheck);
        };
    }, []);

    useEffect(() => {
        if (openBasic) {
            setIsToggeled(true);
        } else {
            setIsToggeled(false);
        }
    }, [openBasic]);

    return (
        <>
            <MDBNavbar id='upper_navbar' expand='lg'>
                <MDBContainer fluid id='upper_nav_container'>
                    <MDBNavbarNav className='d-flex flex-row justify-content-start' id='upper_nav'>
                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={handleCall}
                            >
                                <FaPhoneAlt style={{ marginRight: "10px" }} />
                                {checkResponsive() ? "" : "+971 50 260 0721"}
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={handleMail}
                            >
                                <IoMdMail style={{ marginRight: "10px", fontSize: '18px' }} />
                                {checkResponsive() ? "" : "info@menaproptech.com"}
                            </button>
                        </MDBNavbarItem>
                    </MDBNavbarNav>

                    <div id='lang_btn'>
                        <LanguageSwitch />
                    </div>
                </MDBContainer>
            </MDBNavbar>

            <MDBNavbar expand='lg'
                className={`navbar_${isScrolled ? 'scrolled' : ''}`}
                id={`main_navbar${isScrolled ? 'scrolled' : ''}`}>
                <MDBContainer fluid id='navbar_container'>
                    <MDBNavbarBrand id='logo_container'>
                        <img
                            id='logo'
                            src={logo} alt="logo"
                            onClick={() => window.location.reload()}
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setOpenBasic(!openBasic)}
                        style={{ paddingRight: '7%', color: '#083555' }}
                    >
                        {isToggeled ? <MDBIcon fas icon="times" /> : <MDBIcon className='bars_icon' icon='bars' fas />}
                    </MDBNavbarToggler>

                    <MDBCollapse navbar open={openBasic} id='collapse_container'>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0' id='navbar'>
                            <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("home_section")} >
                                    {t('navigation.home')}
                                </button>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("about_section")} >
                                    {t('navigation.about')}
                                </button>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("service_section")} >
                                    {t('navigation.services')}
                                </button>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("blogs_section")} >
                                    {t('navigation.blogs')}
                                </button>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("form_section")} >
                                    {t('navigation.contact')}
                                </button>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </>
    );
};

export default Navigation;
