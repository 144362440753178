import React from "react";
import { useTranslation } from "react-i18next";

import './home.css';

import homeImage from "./Objects.png";
import dataImage from './Dashboard-bro (2).png';
import salesImage from './Consulting-rafiki (3).png';
import eventImage from './Conference-amico.png';
import trainingImage from './college project-cuate.png';
import data_bg from './data.png';
import Cnsltncy_bg from './concultancy.png';
import event_bg from './event.png';
import trnng_bg from './training.png';

const Home = () => {

    const { t } = useTranslation();

    const handleCall = () => {
        window.location.href = `tel:+971 50 260 0721`
    };

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };


    return (
        <>

            <section id="hero_section">

                <section id="home_section">
                    <div id="home_text_div">
                        <h1 id="home_main_hdng">
                            {t('home.main_heading')}
                        </h1>
                        <p id="home_text">
                            {t('home.sub_heading')}
                        </p>

                        <div id="buttons_div">
                            <button id="learn_btn"
                                onClick={() => sectionScrollHandler('form_section')}
                            >
                                {t('home.touch_btn')}
                            </button>
                            <button id="call_btn" onClick={handleCall}>
                                {t('home.call_btn')}
                            </button>
                        </div>
                    </div>
                    <div id="home_img_div">
                        <img id="home_img" src={homeImage} alt="" />
                    </div>
                </section>


                {/* <section id="info_section">
                    <div className="info_box" id="data_info_div">
                        <div className="info_bg_div">
                            <div className="info_icon_div">
                                <img className="info_icn" src={dataImage} alt="" />
                            </div>
                            <h3 className="info_hdng">
                                AI Data-Driven Insights
                            </h3>
                            <p className="info_txt">
                                AI-powered insights for market trends, ROI projections, and risk assessments.
                            </p>
                        </div>
                    </div>

                    <hr className="info_separator" />

                    <div className="info_box" id="cnslt_info_div">
                        <div className="info_bg_div">
                            <div className="info_icon_div">
                                <img className="info_icn" src={salesImage} alt="" />
                            </div>
                            <h3 className="info_hdng">
                                AI Marketing & Sales
                            </h3>
                            <p className="info_txt">
                                Optimize your sales with tailored primary and secondary market campaigns.
                            </p>
                        </div>
                    </div>

                    <hr className="info_separator" />

                    <div className="info_box" id="event_info_div">
                        <div className="info_bg_div">
                            <div className="info_icon_div">
                                <img className="info_icn" src={eventImage} alt="" />
                            </div>
                            <h3 className="info_hdng">
                                Conferences
                            </h3>
                            <p className="info_txt">
                                Annual Property Technology conference, connecting innovators and industry leaders.
                            </p>
                        </div>
                    </div>

                    <hr className="info_separator" />

                    <div className="info_box" id="trnng_info_div">
                        <div className="info_bg_div">
                            <div className="info_icon_div">
                                <img className="info_icn" src={trainingImage} alt="" />
                            </div>
                            <h3 className="info_hdng">
                                PropTech Exports Training
                            </h3>
                            <p className="info_txt">
                                Certified & Placement guaranteed trainings to stay ahead in the Real Estate Tech industry.
                            </p>
                        </div>
                    </div>
                </section> */}

            </section>

        </>
    );
};

export default Home;

