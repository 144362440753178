import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router, // Use BrowserRouter for most use cases
  Routes,
  Route
} from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import FortifyPage from "./pages/FortifyPage/FortifyPage";
import DatastatePage from "./pages/DatastatePage/DatastatePage";
import MarketPlacePage from "./pages/MarketPlacePage/MarketPlacePage";
import ProptechServicesPage from "./pages/ProptechServicesPage/ProptechServicesPage";

const App = () => {
  return (
    <>

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/market-place" element={<MarketPlacePage />} />
          <Route path="/products/fortify" element={<FortifyPage />} />
          <Route path="/products/datastate" element={<DatastatePage />} />
          <Route path="/services" element={<ProptechServicesPage />} />
        </Routes>
      </Router>

      {/* <Navigation />
      <Home />
      <About />
      <Services />
      <Event />
      <Why />
      <Testimonials />
      <Blogs />
      <Affiliate />
      <Form />
      <Footer /> */}
    </>
  );
};

export default App;


// #F11927 red
// #231F20 gray
// #edf2f4 white
// 457b9d lightblue
// 1d3557 darkblu 
