import React from 'react';

const FortifyPage = () => {

    return (
        <>
            <iframe src="https://fortify.landtrack.pk/" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
        </>
    );
};

export default FortifyPage;